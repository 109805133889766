import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import statusPageRouter from './router/statusPage.js'
import store from './store'
import initAxios from '@/services/axios.js'

import GAuth from 'vue-google-oauth2'
import VueMultiselect from 'vue-multiselect'
import VueTelInput from 'vue-tel-input'
import VueMeta from 'vue-meta'
import VueMq from 'vue-mq'
import VModal from 'vue-js-modal'
import VueClipboard from 'vue-clipboard2'
import vClickOutside from 'v-click-outside'

import * as Sentry from '@sentry/browser'
import { Vue as VueIntegration } from '@sentry/integrations'

import { loadBaseComponents, loadIcons, bootstrap } from './bootstrap.js'
import auth from '@/services/auth.js'

import '@/directives/directives.js'
import '@/services/validation.js'
import '@/filters/filters.js'
import AccountUnverified from './components/Account/AccountUnverified'

import VueLivePreview from 'vue-live-preview'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/material.css'

// So that we still get console errors in development
if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })]
  })
}

Vue.config.productionTip = false
Vue.config.performance = true

loadBaseComponents()
loadIcons()
initAxios()

export const bus = new Vue()
export const firstMonitor = new Vue()
export const monitorsAddedSuccessfully = new Vue()

export const reponseTimeTableUpdate = new Vue()

// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
// Allow `<zapier-full-experience>` custom element
// -*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-*-
Vue.config.ignoredElements = ['zapier-full-experience']

const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account',
  plugin_name: 'Pulsetic'
}
if (window.location.host === 'app.pulsetic.com' || window.location.host === 'dev.pulsetic.com' || window.location.host === 'localhost:8080') {
  Vue.use(GAuth, gauthOption)
}

Vue.component('multiselect', VueMultiselect)
Vue.component('AccountUnverified', AccountUnverified)
Vue.use(VModal, { componentName: 'v-modal' })
Vue.use(VueTelInput)
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})
Vue.use(VueLivePreview, {
  theme: 'material',
  tabSize: 2,
  lineNumbers: false
})
Vue.use(VueClipboard)
Vue.use(vClickOutside)

Vue.use(VueMq, {
  breakpoints: {
    mobile: 581,
    tablet: 881,
    desktop: Infinity
  },
  defaultBreakpoint: 'sm' // customize this for SSR
})

if (auth.isAuthenticated()) {
  bootstrap().then(() => {
    initApp()
  })
} else {
  initApp()
}

function initApp () {
  if (window.location.hostname === process.env.VUE_APP_CORE_DOMAIN) {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  } else {
    new Vue({
      router: statusPageRouter,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
}
