import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/services/auth.js'
import { Role } from '../_helpers/role'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue')
  },
  {
    path: '/autologin',
    name: 'autologin',
    component: () => import('@/views/auth/autologin.vue')
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('@/views/auth/register.vue')
  },
  {
    path: '/forgot-password',
    name: 'forgotPassword',
    component: () => import('@/views/auth/forgot-password.vue')
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: () => import('@/views/auth/reset-password.vue')
  },
  {
    path: '/subscriptions',
    name: 'subscriptions',
    component: () => import('@/views/status-pages/subscriptions/index.vue')
  },
  {
    path: '/two-factor-authentication-check',
    name: 'twoFactorAuthenticationCheck',
    component: () => import('@/views/auth/two-factor-authentication-check.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/components/Layouts/Dashboard/Dashboard.vue'),
    redirect: () => {
      return { name: 'monitors.list' }
    },
    children: [
      {
        path: '/monitors',
        name: 'monitors.list',
        component: () => import('@/views/monitors/index.vue')
      },
      {
        path: '/monitors/:id',
        name: 'monitors.single',
        props: true,
        component: () => import('@/views/monitors/_id/index.vue'),

        redirect: () => {
          return { name: 'monitors.single.overview' }
        },

        children: [
          {
            path: 'overview',
            name: 'monitors.single.overview',
            component: () => import('@/views/monitors/_id/overview.vue')
          },
          {
            path: 'latest-check',
            name: 'monitors.single.latestCheck',
            component: () => import('@/views/monitors/_id/latest-check.vue')
          },
          {
            path: 'nodes',
            name: 'monitors.single.nodes',
            component: () => import('@/views/monitors/_id/locations.vue')
          },
          {
            path: 'failed-checks',
            name: 'monitors.single.failedChecks',
            component: () => import('@/views/monitors/_id/failed-checks/index.vue')
          },
          {
            path: 'checks/:checkId',
            props: true,
            name: 'monitors.single.checks.single',
            component: () => import('@/views/monitors/_id/failed-checks/_id/index.vue')
          },
          {
            path: 'alerts',
            name: 'monitors.single.notifications',
            component: () => import('@/views/monitors/_id/notifications/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] },
            redirect: () => ({
              name: 'monitors.single.notifications.email'
            }),

            children: [
              {
                path: 'email',
                name: 'monitors.single.notifications.email',
                component: () => import('@/views/monitors/_id/notifications/email.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'sms-calls',
                name: 'monitors.single.notifications.sms-calls',
                component: () => import('@/views/monitors/_id/notifications/sms-calls.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'slack',
                name: 'monitors.single.notifications.slack',
                component: () => import('@/views/monitors/_id/notifications/slack.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'telegram',
                name: 'monitors.single.notifications.telegram',
                component: () => import('@/views/monitors/_id/notifications/telegram.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'sms',
                name: 'monitors.single.notifications.sms',
                component: () => import('@/views/monitors/_id/notifications/sms.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'voice',
                name: 'monitors.single.notifications.voice',
                component: () => import('@/views/monitors/_id/notifications/voice.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'webhook',
                name: 'monitors.single.notifications.webhook',
                component: () => import('@/views/monitors/_id/notifications/webhook.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'signl4',
                name: 'monitors.single.notifications.signl4',
                component: () => import('@/views/monitors/_id/notifications/signl4.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'discord',
                name: 'monitors.single.notifications.discord',
                component: () => import('@/views/monitors/_id/notifications/discord.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'msteams',
                name: 'monitors.single.notifications.msteams',
                component: () => import('@/views/monitors/_id/notifications/msteams.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'zapier',
                name: 'monitors.single.notifications.zapier',
                component: () => import('@/views/monitors/_id/notifications/zapier.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              }
            ]
          },
          {
            path: 'settings',
            name: 'monitors.single.settings',
            component: () => import('@/views/monitors/_id/settings/index.vue')
          },
          {
            path: 'settings',
            name: 'monitors.single.settings',
            component: () => import('@/views/monitors/_id/settings/index.vue')
          },
          {
            path: 'maintenance-windows',
            name: 'monitors.single.maintenanceWindows',
            component: () => import('@/views/monitors/_id/maintenance-windows.vue')
          },
          {
            path: 'delete',
            name: 'monitors.single.delete',
            component: () => import('@/views/monitors/_id/delete.vue')
          }
        ]
      },

      {
        path: '/status-pages',
        name: 'statusPages.list',
        component: () => import('@/views/status-pages/index.vue')
      },
      {
        path: '/status-pages/new',
        name: 'statusPages.new',
        component: () => import('@/views/status-pages/new.vue'),
        meta: { authorize: [Role.Admin, Role.Editor] }
      },
      {
        path: '/status-pages/:id',
        name: 'statusPages.update',
        props: true,
        component: () => import('@/views/status-pages/_id/index.vue'),

        redirect: () => ({
          name: 'statusPages.single.generalSettings'
        }),

        children: [
          {
            path: 'general-settings',
            name: 'statusPages.single.generalSettings',
            props: true,
            component: () => import('@/views/status-pages/_id/settings/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'monitors',
            name: 'statusPages.single.monitors',
            props: true,
            component: () => import('@/views/status-pages/_id/monitors/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'customization',
            name: 'statusPages.single.customization',
            props: true,
            component: () => import('@/views/status-pages/_id/customization/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'maintenance',
            name: 'statusPages.single.maintenance',
            props: true,
            component: () => import('@/views/status-pages/_id/maintenance/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'maintenance/create',
            name: 'statusPages.single.maintenance.create',
            props: true,
            component: () => import('@/views/status-pages/_id/maintenance/_id/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'maintenance/:maintenanceId',
            name: 'statusPages.single.maintenance.single',
            props: true,
            component: () => import('@/views/status-pages/_id/maintenance/_id/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'incidents',
            name: 'statusPages.single.incidents',
            props: true,
            component: () => import('@/views/status-pages/_id/incidents/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'incidents/:incidentId',
            name: 'statusPages.single.incidents.single',
            props: true,
            component: () => import('@/views/status-pages/_id/incidents/_id/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'subscribers',
            name: 'statusPages.single.subscribers',
            props: true,
            component: () => import('@/views/status-pages/_id/subscribers/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'language',
            name: 'statusPages.single.language',
            props: true,
            component: () => import('@/views/status-pages/_id/language/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          }
        ]
      },

      {
        path: '/status-badges',
        name: 'BadgesPage',
        component: () => import('@/views/badge-pages/index.vue')
      },

      {
        path: '/status-badges/:id',
        name: 'BadgePage',
        props: true,
        component: () => import('@/views/badge-pages/_id.vue')
      },

      {
        path: '/account',
        name: 'account',
        component: () => import('@/views/account/index.vue'),
        redirect: () => ({
          name: 'account.profile'
        }),

        children: [
          {
            path: 'profile',
            name: 'account.profile',
            component: () => import('@/views/account/profile/index.vue')
          },
          {
            path: 'subscription',
            name: 'account.subscription',
            component: () => import('@/views/account/subscription/index.vue'),
            meta: { authorize: [Role.Admin] }
          },
          {
            path: 'receipts',
            name: 'account.receipts',
            component: () => import('@/views/account/receipts/index.vue'),
            meta: { authorize: [Role.Admin, Role.Accountant] }
          },
          {
            path: 'delete',
            name: 'account.delete',
            component: () => import('@/views/account/delete/index.vue')
          },
          {
            path: 'usage',
            name: 'account.usage',
            component: () => import('@/views/account/usage/index.vue'),
            meta: { authorize: [Role.Admin] }
          },
          {
            path: 'teammates',
            name: 'account.teammates',
            component: () => import('@/views/account/teammates/index.vue'),
            meta: { authorize: [Role.Admin] }
          },
          {
            path: 'api',
            name: 'account.api',
            component: () => import('@/views/account/api/index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] }
          },
          {
            path: 'tfa',
            name: 'account.tfa',
            component: () => import('@/views/account/two-factor-authentication/index.vue'),
            meta: { authorize: [Role.Admin] }
          },
          {
            path: 'monitor-settings',
            name: 'account.monitor.settings',
            component: () => import('@/views/account/monitor-settings/index.vue'),
            meta: { authorize: [Role.Admin] }
          },
          {
            path: 'general-alerts',
            name: 'account.general-notifications',
            component: () => import('@/views/account/general-alerts/general_index.vue'),
            meta: { authorize: [Role.Admin, Role.Editor] },
            redirect: () => ({
              name: 'account.general-notifications.email'
            }),

            children: [
              {
                path: 'email',
                name: 'account.general-notifications.email',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/email.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'sms-calls',
                name: 'account.general-notifications.sms-calls',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/sms-calls.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'slack',
                name: 'account.general-notifications.slack',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/slack.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'msteams',
                name: 'account.general-notifications.msteams',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/msteams.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'telegram',
                name: 'account.general-notifications.telegram',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/telegram.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'sms',
                name: 'account.general-notifications.sms',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/sms.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'voice',
                name: 'account.general-notifications.voice',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/voice.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'webhook',
                name: 'account.general-notifications.webhook',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/webhook.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'signl4',
                name: 'account.general-notifications.signl4',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/signl4.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'discord',
                name: 'account.general-notifications.discord',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/discord.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              },
              {
                path: 'zapier',
                name: 'account.general-notifications.zapier',
                props: { general: true },
                component: () => import('@/views/monitors/_id/notifications/zapier.vue'),
                meta: { authorize: [Role.Admin, Role.Editor] }
              }
            ]
          }
        ]
      },

      {
        path: 'notifications',
        name: 'account.notifications',
        component: () => import('@/views/notifications/index.vue')
      }
    ]
  },
  {
    path: '/status/:slug',
    name: 'status',
    component: () => import('@/views/status/index.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'status.overview',
        component: () => import('@/views/status/status-overview.vue'),
        props: true
      },
      {
        path: 'history',
        name: 'status.history',
        component: () => import('@/views/status/status-history.vue')
      }
    ]
  },
  // {
  //   path: '/status/:slug',
  //   name: 'status',
  //   component: () => import('@/views/status.vue'),
  //   props: true
  // },
  {
    path: '/email_verify',
    name: 'email_verify',
    component: () => import('@/views/email_verify')
  },
  {
    path: '/status-page-email-verify',
    name: 'status-page-email-verify',
    component: () => import('@/views/status_page_email_verify')
  },
  {
    path: '/operational/slack-callback',
    name: 'operational.slackCallback',
    component: () => import('@/components/Operational/SlackCallback.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Status pages are public by default
  if (to.name === 'status' || to.name === 'email_verify' || to.name === 'status.overview' || to.name === 'status.history' || to.name === 'status-page-email-verify') {
    next()
  } else if (to.name === 'statusPages.single.incidents') {
    next()
  } else {
    // Routes which are not accessible by authorized users
    const publicRoutes = [
      'login',
      'autologin',
      'signup',
      'forgotPassword',
      'resetPassword',
      'subscriptions',
      'twoFactorAuthenticationCheck'
    ]

    const forAuthenticated = !publicRoutes.includes(to.name)
    const authenticated = auth.isAuthenticated()

    if (authenticated) {
      if (process.env.NODE_ENV === 'production') {
        const currentUser = auth.getUser()
        window.Beacon('identify', {
          name: currentUser.name ? currentUser.name : currentUser.email,
          email: currentUser.email
        })
      }
      window.sib = {
        equeue: [],
        client_key: 'c5fvmzrvqpdhg2kfh6ccmp55'
      }
      window.sib.email_id = auth.getUser().email
      window.sendinblue = {}
      var j = ['track', 'identify', 'trackLink', 'page']
      let i = 0
      for (; i < j.length; i++) {
        (function (k) {
          window.sendinblue[k] = function () {
            var arg = Array.prototype.slice.call(arguments);
            (window.sib[k] || function () {
              var t = {}
              t[k] = arg
              window.sib.equeue.push(t)
            })(arg[0], arg[1], arg[2], arg[3])
          }
        })(j[i])
      }
      let n = document.createElement('script')
      i = document.getElementsByTagName('script')[0]
      n.type = 'text/javascript'
      n.id = 'sendinblue-js'
      n.async = !0
      n.src = 'https://sibautomation.com/sa.js?key=' + window.sib.client_key
      i.parentNode.insertBefore(n, i)
      window.sendinblue.page()
    }

    if (forAuthenticated && !authenticated) {
      return next({ name: 'login' })
    }

    if (!forAuthenticated && authenticated) {
      if (to.name === 'login' && to.query.source === 'appsumo') {
        return next()
      }
      return next({ name: 'home' })
    }
    const { authorize } = to.meta
    const currentUser = auth.getUser()

    if (authorize && authorize.length && !authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }

    next()
  }
})

export default router
